import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_5228da2d from './schemes/hubAuthScheme.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"","home":"/","callback":false},"vuex":{"namespace":"auth"},"cookie":{"prefix":"qontak.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/auth/login","method":"post","propertyName":"token"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/user","method":"get","propertyName":"user"}},"_name":"local"}))

  // hub
  $auth.registerStrategy('hub', new scheme_5228da2d($auth, {"endpoints":{"logout":{"url":"https://chat-service.qontak.com/api/core/v1/users/sign_out","method":"post"},"login":{"url":"https://chat-service.qontak.com/api/core/v1/users/sign_in","method":"post","property":"data.access_token"},"user":{"url":"https://chat-service.qontak.com/api/core/v1/users/me","method":"get","propertyName":"data"},"refresh":{"method":"post","property":"data.access_token"}},"tokenRequired":true,"refreshToken":true,"tokenType":"bearer","_name":"hub"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
