import LocalScheme from '@nuxtjs/auth/lib/schemes/local'
import { storeUserLoggedInLocalStorage } from '~/utils/general'
const axios = require('axios')

export default class HubAuthScheme extends LocalScheme {
  reset() {
    if (this.options.tokenRequired) {
      this._clearToken()
    }

    this.$auth.setUser(false)
    this.$auth.setToken(this.name, false)
    this.$auth.setRefreshToken(this.name, false)

    return Promise.resolve()
  }

  async logout(params) {
    // Only connect to logout endpoint if it's configured
    if (this.options.endpoints.logout) {
      this.options.endpoints.logout.data = params // important things
      await this.$auth.request(params, this.options.endpoints.logout, true)
    }
    // But reset regardless
    return this.$auth.reset()
  }

  async login(payload) {
    if (!this.options.endpoints.login) {
      return
    }

    const requestBody = {
      data: {
        ...payload.data,
        is_direct: !!payload.data.is_direct,
        is_sso: !!payload.data.sso,
      },
    }

    // Ditch any leftover local tokens before attempting to log in
    await this.$auth.reset()

    const { response, result } = await this.$auth.request(
      requestBody,
      this.options.endpoints.login,
      true
    )

    if (this.options.tokenRequired) {
      const token = this.options.tokenType
        ? this.options.tokenType + ' ' + result.data.access_token
        : result.data.access_token

      this.$auth.setToken(this.name, token)

      // this._setToken(response)

      if (this.options.refreshToken) {
        const refreshToken = response.data.data.refresh_token
        this.$auth.setRefreshToken(this.name, refreshToken)
      }
    }

    if (!requestBody?.data?.is_sso && !requestBody?.data?.is_direct) {
      await this.fetchUser()
      storeUserLoggedInLocalStorage(this.$auth.user.email)
    }

    return response
  }

  async refresh(payload) {
    let url = `${process.env.HUB_SERVICE_URL}/oauth/token`
    if (payload.data.is_sso) {
      url = `${process.env.HUB_SERVICE_URL}/sso_oauth/token`.replace(
        /([^:]\/)\/+/g,
        '$1'
      )
    }
    this.options.endpoints.refresh.url = url

    const { response, result } = await this.$auth.request(
      payload,
      this.options.endpoints.refresh,
      true
    )

    if (this.options.tokenRequired) {
      const token = this.options.tokenType
        ? this.options.tokenType + ' ' + result.data.access_token
        : result.data.access_token

      this.$auth.setToken(this.name, token)

      // this._setToken(response)

      if (this.options.refreshToken) {
        const refreshToken = response.data.data.refresh_token
        this.$auth.setRefreshToken(this.name, refreshToken)
      }

      const responseAccount = await this.$auth.requestWith(
        this.name,
        this.options.endpoints.user
      )
      this.$auth.setUser(responseAccount)
    }
  }

  async fetchUser() {
    const cookieObj = new URLSearchParams(
      document.cookie.replaceAll('&', '%26').replaceAll('; ', '&')
    )
    const validTokenRefresh = cookieObj.get('qontak._refresh_token.hub')

    // Token is required but not available
    if (this.options.tokenRequired && !this.$auth.getToken(this.name)) {
      return
    }

    // User endpoint is disabled.
    if (!this.options.endpoints.user) {
      this.$auth.setUser({})
      return
    }

    let isUsingSSO = false
    await axios
      .get(`${process.env.HUB_SERVICE_URL}/api/core/v1/client_configs/config`)
      .then(({ data: response }) => {
        const { data } = response
        isUsingSSO =
          process.env.NODE_ENV === 'development'
            ? false
            : data?.sso?.is_using_parameter || data?.sso?.status
      })

    try {
      const payload = {}
      const response = await this.$auth.requestWith(
        this.name,
        payload,
        this.options.endpoints.user
      )
      this.$auth.setUser(response)
    } catch (error) {
      if (isUsingSSO) {
        const refreshTokenPayload = {
          data: {
            refresh_token: validTokenRefresh,
            grant_type: 'refresh_token',
            is_sso: isUsingSSO,
          },
        }
        await this.refresh(refreshTokenPayload)
      }
    }
  }
}
